@import "../../node_modules/flexboxgrid/dist/flexboxgrid.css";

.layout_debug {
  border: 1px solid lightblue;
}

.blank_button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.sf-pagination {
  margin-left: auto;
}

.sf-items-table {
  .sf-header {
    text-transform: capitalize;
    min-width: 60px;
    cursor: pointer;
    user-select: none;
  }

  .sf-sort-symbol {
    float: right;
    padding-right: 5px;
    padding-left: 5px;
    min-width: 10px;
    max-width: 10px;
  }
}

.sf-refinement-list {
  .slds-tree__item {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  [aria-level="0"] > .slds-tree__item {
    padding-left: 0rem;
  }
}

.sf-options-panel {
  .sf-item {
    margin: auto;
    display: flex;

    .sf-name {
      align-self: center;
      flex: 50%;
    }

    .slds-dropdown-trigger {
      flex: 50%;

      .slds-button {
        width: 100%;
        max-width: 100%;
        white-space: nowrap;
      }
    }
  }
}

.slds-region_narrow.sizing-50-50 {
  .slds-dl_horizontal {
    .slds-dl_horizontal__label {
      width: 50%;
    }

    .slds-dl_horizontal__detail {
      width: 50%;
    }
  }
}
